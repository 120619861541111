import React, { useState, useEffect } from "react";
import LinkPreviewComponent from "./LinkPreviewComponent";

const ParagraphWithLinkPreview = ({ paragraph }) => {
  const [previewUrl, setPreviewUrl] = useState("");

  useEffect(() => {
    const urlRegex = /(https?:\/\/[^\s]+)|(www\.[^\s]+)/gi;
    const foundUrls = paragraph.match(urlRegex); // Extract all URLs from the paragraph
    if (foundUrls && foundUrls.length > 0) {
      const normalizedUrl = foundUrls[0].startsWith("http")
        ? foundUrls[0]
        : `http://${foundUrls[0]}`;
      setPreviewUrl(normalizedUrl); // Automatically set the first URL
    }
  }, [paragraph]); // Re-run whenever paragraph changes

  const renderedParagraph = paragraph.split(/(https?:\/\/[^\s]+)|(www\.[^\s]+)/gi).map((part, index) => {
    if (part && /(https?:\/\/[^\s]+)|(www\.[^\s]+)/gi.test(part)) {
      const normalizedUrl = part.startsWith("http") ? part : `http://${part}`;
      return (
        <a key={index} href={normalizedUrl} target="_blank" rel="noopener noreferrer">
          {part}
        </a>
      );
    }
    return <span key={index}>{part}</span>;
  });

  return (
    <div>
      <div className="content">{renderedParagraph}</div>
      {previewUrl && (
         <LinkPreviewComponent url={previewUrl} />
      )}
    </div>
  );
};

export default ParagraphWithLinkPreview;
