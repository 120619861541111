import axios from "axios";
import React, { Fragment } from "react";
import { useState } from "react";
import {
  reactionsUrl,
  baseUrl,
  createFeedsUrl,
  allMembersUrl,
  allMicrositeUrl,
  chatMessagesUrl,
} from "../../api/Api";
import { useEffect } from "react";
import { useContext } from "react";
import { UserContext } from "../../utils/UserContext";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import ShareIcon from "@mui/icons-material/Share";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { ThreeDots } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PersonPinIcon from "@mui/icons-material/PersonPin";
import FacebookIcon from "@mui/icons-material/Facebook";
import TelegramIcon from "@mui/icons-material/Telegram"; // Import the icon if using Material UI
import SearchIcon from "@mui/icons-material/Search";
import ParagraphWithLinkPreview from "../ParagraphWithLinkPreview";
import Modal from "@mui/material/Modal";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import { useGetGroupDataQuery } from "../../api/slices/groupsApiSlice";
import {
  useRemoveReactionMutation,
  useStoreReactionMutation,
} from "../../api/slices/loungeApiSlice";
import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogContentText,
  Divider,
  FormControlLabel,
  FormGroup,
  InputLabel,
  Radio,
  RadioGroup,
  TextareaAutosize,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import parser from "html-react-parser";
import { useStoreFeedMutation } from "../../api/slices/loungeApiSlice";
import likeImage from "../../asset/image/like.png";
import loveImage from "../../asset/image/heart.png";
import EmojiFlagsIcon from "@mui/icons-material/EmojiFlags";
import happyImage from "../../asset/image/happy.png";
import LockIcon from "@mui/icons-material/Lock";
import SettingsIcon from "@mui/icons-material/Settings";
import adoreImage from "../../asset/image/adore.png";
import messenger from "../../asset/image/messenger.png";
import laughImage from "../../asset/image/laugh.png";
import angryImage from "../../asset/image/angry.png";
import ic from "../../asset/image/ic.png";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import sadImage from "../../asset/image/sad.png";
import CloseIcon from "@mui/icons-material/Close";
import XIcon from "@mui/icons-material/X";
import LinkPreviewComponent from "../LinkPreviewComponent"; // Adjust the path accordingly
import GroupIcon from "@mui/icons-material/Group";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LinkIcon from "@mui/icons-material/Link";
import { ShimmerPostItem } from "react-shimmer-effects";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import PublicIcon from "@mui/icons-material/Public";
import DialogTitle from "@mui/material/DialogTitle";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";

const LoungeItemContent = ({
  data,
  itemModalClick,
  getAllFeeds,
  getSingleFeedDetails,
  pinned,
  notification,
}) => {
  const navigate = useNavigate();
  const [paragraph, setParagraph] = useState("");
  const [myReaction, setMyReaction] = useState(null);
  const [meaning, setmeaning] = useState("");
  const [loaderVisible, setLoaderVisible] = useState(false);
  const { msDetails, userDetails } = useContext(UserContext);
  const url = window.location.href;
  const segName = url.split("/").pop();
  const [progressValue, setProgressValue] = useState(0);
  const token = localStorage.getItem("token");
  const [storeFeed] = useStoreFeedMutation();
  const [copiedLink, setCopiedLink] = useState("");
  const [storeReaction] = useStoreReactionMutation();
  const [removeReaction] = useRemoveReactionMutation();

  const [storeLike, setStoreLike] = useState([]);
  const [storeLove, setStoreLove] = useState([]);
  const [storeHappy, setStoreHappy] = useState([]);
  const [storeAdore, setStoreAdore] = useState([]);
  const [storeLaugh, setStoreLaugh] = useState([]);
  const [storeAngry, setStoreAngry] = useState([]);
  const [storeSad, setStoreSad] = useState([]);
  const [userInfo, setUserInfo] = useState(null); // Controls which content to show
  const [userProfile, setuserProfile] = useState(null); // Controls which content to show
  const [modalContent, setModalContent] = useState(null); // Controls which content to show
  const [shareUrl, setshareUrl] = useState(null); // Controls which content to show
  const [shareUuid, setshareUuid] = useState(null); // Controls which content to show
  const [notifications, setNotification] = useState(null); // State to hold the notification message
  const microsite_id = msDetails?.id;
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(null);
  const [totalLength, setTotalLength] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const {
    data: getGroupData,
    isFetching: getDataFetching,
    isError: getDataError,
  } = useGetGroupDataQuery({ token, microsite_id, currentPage, searchValue });
  const [groupData, setGroupData] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedGroupIds, setSelectedGroupIds] = useState([]);
  const [allMembers, setAllMembers] = useState(null);
  const [linkedMicrosites, setLinkedMicrosites] = useState(null);
  const [keyword, setKeyword] = useState(""); // Track search input
  const [selectedIds, setSelectedIds] = useState([]); // Store selected IDs
  const [linkedMicrositeMessage, setlinkedMicrositeMessage] =
    useState("Loading..."); // Store selected IDs

  // Fetch microsites with both linking_keywords and keyword
  const getlinkedMicrosites = (searchKeyword = "") => {
    const encodedLinkingKeywords = encodeURIComponent(
      msDetails?.linking_keywords?.trim() || ""
    );
    const encodedSearchKeyword = encodeURIComponent(searchKeyword.trim());
    if (encodedLinkingKeywords && msDetails.enable_linking == 1) {
      const config = {
        method: "get",
        url: `${allMicrositeUrl}?linking_keywords=${encodedLinkingKeywords}&keywords=${encodedSearchKeyword}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      axios
        .request(config)
        .then((response) => {
          setLinkedMicrosites(response.data?.data || []);
          if (linkedMicrosites.length === 0) {
            setlinkedMicrositeMessage("No Linked community found!!");
          }
        })
        .catch((error) => {
          setlinkedMicrositeMessage("No Linked community found!!");
        });
    } else {
      setlinkedMicrositeMessage(
        "Linking is not enabled! Please enable linking to use this feature."
      );
    }
  };
  // Debounce search for efficiency
  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      getlinkedMicrosites(keyword);
    }, 500);

    return () => clearTimeout(delayDebounce);
  }, [keyword]); // Trigger on keyword change

  // Function to remove HTML tags and trim to 70 characters
  const cleanText = (html) => {
    // Remove HTML tags
    const text = html.replace(/<\/?[^>]+(>|$)/g, "");
    // Trim to 250 characters
    return text.length > 70 ? text.slice(0, 70) + "..." : text;
  };
  //get all members
  const membersUrl = `${allMembersUrl}/${msDetails.id}`;
  const [selectedProfiles, setSelectedProfiles] = useState([]);
  const [message, setMessage] = useState("");
  const [memberSearchKeyword, setMemberSearchKeyword] = useState("");

  const getAllMembers = () => {
    let config = {
      method: "get",
      url: `${membersUrl}?keyword=${memberSearchKeyword}`, // Use the search keyword
    };
  
    axios
      .request(config)
      .then((response) => {
        setAllMembers(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching members:", error);
      });
  };
  const handleProfileSelection = (user) => {
    setSelectedProfiles((prevSelected) => {
      const alreadySelected = prevSelected.some(
        (selectedUser) => selectedUser.uuid === user.uuid
      );

      if (alreadySelected) {
        // Remove profile if already selected
        return prevSelected.filter(
          (selectedUser) => selectedUser.uuid !== user.uuid
        );
      } else if (prevSelected.length < 20) {
        // Add profile to the selection if under limit
        return [...prevSelected, user];
      } else {
        alert("You can select a maximum of 20 profiles.");
        return prevSelected;
      }
    });
  };
  const handleSubmit = () => {
    if (selectedProfiles.length === 0) {
      setNotification("No profiles selected!");
      return;
    }

    selectedProfiles.forEach((user) => {
      const url = shareUrl; // Replace with your URL structure
      const fullMessage = message.trim() ? `${message}\n${url}` : url;

      // Call the chat handler with the user object and message
      handleMemberChat(user, fullMessage);
    });
    setNotification("Shared successfully!");
    setSelectedProfiles([]); // Reset selection
    setMessage(""); // Clear the message box
    setTimeout(() => setNotification(null), 3000);
    handleClose();
  };

  // Filter group data based on the search query
  const filteredGroups = groupData?.filter((group) =>
    group?.name?.toLowerCase().includes(searchQuery.toLowerCase())
  );
  // Toggle selection of group IDs
  const handleCheckboxChange = (id) => {
    setSelectedGroupIds(
      (prevSelected) =>
        prevSelected.includes(id)
          ? prevSelected.filter((groupId) => groupId !== id) // Remove if already selected
          : [...prevSelected, id] // Add if not selected
    );
  };
  // Handle checkbox toggling
  const handleCheckboxChangeCommunity = (id) => {
    setSelectedIds(
      (prevSelectedIds) =>
        prevSelectedIds.includes(id)
          ? prevSelectedIds.filter((item) => item !== id) // Remove if already selected
          : [...prevSelectedIds, id] // Add if not selected
    );
  };
  // Share community
  const shareCommunity = () => {
    const formData = new FormData();
    formData.append("shared_community_ids", selectedIds.join(",")); // Send as comma-separated IDs

    const config = {
      method: "post",
      url: `${createFeedsUrl}/${shareUuid}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    };

    axios
      .request(config)
      .then((response) => {
        console.log("Share successful:", response.data);
      })
      .catch((error) => {
        console.error("Error sharing communities:", error);
      });
  };
  // Handle "Done" button click
  const handleDone = async (event) => {
    if (!selectedGroupIds) {
      setCurrentView("main");
    } else {
      event.preventDefault();
      const formData = new FormData();
      formData.append("shared_groupa", JSON.stringify({ selectedGroupIds }));
      const url = `${createFeedsUrl}/${shareUuid}`;

      try {
        const response = await storeFeed({
          token,
          formData,
          url,
          setProgressValue,
        });

        if (response?.data) {
          // Assuming 'ok' is a success indicator
          setProgressValue(0);
          setOpen(false);
          setModalContent(null); // Reset modal content on close
          // setShowFriends(false);
          setCurrentView("main");
          setNotification("Shared successfully!");
        } else {
          setNotification("Failed to share.");
        }
        setTimeout(() => setNotification(null), 3000);
      } catch (error) {
        setNotification("Network error while sharing.");
        console.error("Network error:", error);
        setTimeout(() => setNotification(null), 3000);
      }
    }
  };
  useEffect(() => {
    if (getGroupData?.data) {
      setGroupData(getGroupData?.data);
      setLastPage(getGroupData?.last_page);
      setTotalLength(getGroupData?.total);
    }
  }, [getGroupData]);
  useEffect(() => {
    const userProfileInfo = localStorage.getItem("loggedInUserInfo");
    if (userProfileInfo) {
      const parsedUserInfo = JSON.parse(userProfileInfo);
      setUserInfo(parsedUserInfo);

      if (parsedUserInfo?.user_details) {
        setuserProfile(JSON.parse(parsedUserInfo.user_details));
      }
    }
    getAllMembers();
  }, []);
  useEffect(() => {
    if (
      data &&
      data.content &&
      data.content !== null &&
      data.content !== "" &&
      data !== undefined
    ) {
      setParagraph(data.content);
    }

    // store  actions
    var like = [];
    var love = [];
    var happy = [];
    var adore = [];
    var laugh = [];
    var angry = [];
    var sad = [];
    if (data && data?.reactions && data.reactions.length > 0) {
      data.reactions.forEach((element) => {
        if (element.reaction_type === "like") {
          like.push(element);
        }
        if (element.reaction_type === "love") {
          love.push(element);
        }
        if (element.reaction_type === "happy") {
          happy.push(element);
        }
        if (element.reaction_type === "adore") {
          adore.push(element);
        }
        if (element.reaction_type === "laugh") {
          laugh.push(element);
        }
        if (element.reaction_type === "angry") {
          angry.push(element);
        }
        if (element.reaction_type === "sad") {
          sad.push(element);
        }
      });
      setStoreLike(like);
      setStoreLove(love);
      setStoreHappy(happy);
      setStoreAdore(adore);
      setStoreLaugh(laugh);
      setStoreAngry(angry);
      setStoreSad(sad);
    }

    if (data.my_reactions.length > 0) {
      if (data.my_reactions[0].reaction_type === "like") {
        setMyReaction("👍");
      }
      if (data.my_reactions[0].reaction_type === "love") {
        setMyReaction("❤️");
      }
      if (data.my_reactions[0].reaction_type === "happy") {
        setMyReaction("😊");
      }
      if (data.my_reactions[0].reaction_type === "adore") {
        setMyReaction("🤗");
      }
      if (data.my_reactions[0].reaction_type === "laugh") {
        setMyReaction("😆");
      }
      if (data.my_reactions[0].reaction_type === "angry") {
        setMyReaction("👿");
      }
      if (data.my_reactions[0].reaction_type === "sad") {
        setMyReaction("😥");
      }
    }
  }, [data]);

  //emoji
  const reactionEmoji = {
    "👍": "like",
    "❤️": "love",
    "😊": "happy",
    "🤗": "adore",
    "😆": "laugh",
    "👿": "angry",
    "😥": "sad",
  };
  const [emojiClass, setEmojiClass] = useState(`emojiTab ${data.id} d-none`);
  var feedsEmoji = Object.keys(reactionEmoji);
  // emoji click handler
  const emojiClickHandler = (emoji, postId, uuid) => {
    setLoaderVisible(true);
    setEmojiClass(`emojiTab ${postId} d-none`);
    var meaning = reactionEmoji[emoji];
    setmeaning(meaning);
    setMyReaction(emoji);

    let formData = new FormData();
    formData.append("post_id", postId);
    formData.append("reaction_type", meaning);

    var url;
    if (uuid !== null) {
      url = `${reactionsUrl}/${uuid}`;
    } else {
      url = `${reactionsUrl}`;
    }
    storeReaction({ token, formData, url })
      .unwrap()
      .then((response) => {
        if (getSingleFeedDetails !== undefined) {
          getSingleFeedDetails();
        }
        setTimeout(() => {
          setLoaderVisible(false);
        }, 2000);
      })
      .catch((error) => {
        setLoaderVisible(false);
        //  notifyError('Something went wrong')
      });
  };
  const shareToMessenger = () => {
    const messengerShareUrl = `https://www.facebook.com/dialog/send?link=${encodeURIComponent(
      shareUrl
    )}&app_id=376291595163221&redirect_uri=${encodeURIComponent(shareUrl)}`;
    window.open(messengerShareUrl, "_blank");
  };
  const shareToFacebook = () => {
    const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      shareUrl
    )}`;
    window.open(facebookShareUrl, "_blank");
  };
  const shareToWhatsApp = () => {
    const whatsappShareUrl = `https://wa.me/?text=${encodeURIComponent(
      shareUrl
    )}`;
    window.open(whatsappShareUrl, "_blank");
  };
  // Function to handle sharing to Telegram
  const shareToTelegram = () => {
    const telegramShareUrl = `https://t.me/share/url?url=${encodeURIComponent(
      shareUrl
    )}`;
    window.open(telegramShareUrl, "_blank");
  };
  // Function to handle sharing to LinkedIn
  const shareToLinkedIn = () => {
    const linkedInShareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
      shareUrl
    )}`;
    window.open(linkedInShareUrl, "_blank");
  };
  // Function to handle sharing to X
  const shareToX = () => {
    const xShareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
      shareUrl
    )}`;
    window.open(xShareUrl, "_blank");
  };
  const handleCopyLinks = () => {
    setCopiedLink(shareUrl);
    // Copy the link to clipboard
    navigator.clipboard
      .writeText(shareUrl)
      .then(() => {
        setModalContent("copyLink"); // Set the modal content to copy link view
        setModalOpen(true); // Show modal after copying
      })
      .catch((err) => console.error("Failed to copy the link: ", err));
  };
  const handleCopyLink = (uuid) => {
    const baseUrl = window.location.origin; // assuming base URL is current site
    const link = `${baseUrl}/loungeitemDetail?post_id=${uuid}`;
    setCopiedLink(link);

    // Copy the link to clipboard
    navigator.clipboard
      .writeText(link)
      .then(() => {
        setModalContent("copyLink"); // Set the modal content to copy link view
        setModalOpen(true); // Show modal after copying
      })
      .catch((err) => console.error("Failed to copy the link: ", err));
  };

  // handle remove reaction
  const handleRemoveReaction = (uuid) => {
    if (uuid !== null) {
      var url = `${reactionsUrl}/${uuid}`;

      removeReaction({ token, url })
        .then((response) => {
          setMyReaction(null);

          setStoreLike([]);
          setStoreLove([]);
          setStoreHappy([]);
          setStoreAdore([]);
          setStoreLaugh([]);
          setStoreAngry([]);
          setStoreSad([]);
        })
        .catch((error) => {
          // notifyError('Something went wrong !')
        });
    }
  };
  // handle emoji class
  const handleEmoji = (id, emojiClass) => {
    if (emojiClass === `emojiTab ${id} d-none`) {
      setEmojiClass(`emojiTab ${id}`);
    } else {
      setEmojiClass(`emojiTab ${id} d-none`);
    }
  };
  // handle chat Member

  const handleMemberChat = (user, fullMessage) => {
    setLoaderVisible(true);
    let config = {
      method: "get",
      url: `${chatMessagesUrl}?user=${user.uuid}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        if (response?.data?.meta?.chat_room !== null) {
          handleSubmitPost(fullMessage, response?.data?.meta?.chat_room);
        } else {
          var data = JSON.stringify({
            message: "chat_room_create",
            chat_room: null,
            user: user,
          });
          let config = {
            method: "post",
            url: chatMessagesUrl,
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            data: data,
          };

          axios
            .request(config)
            .then((response) => {
              // handleChatDetails(response?.data?.data?.chat_room.uuid);
              // setSingleRoom(response?.data?.data?.chat_room);
              handleSubmitPost(fullMessage, response?.data?.data?.chat_room);
              setLoaderVisible(false);
            })
            .catch((error) => {
              setLoaderVisible(false);
            });
        }
      })
      .catch((error) => {
        setLoaderVisible(false);
      });
  };
  // hnadle submit post
  const handleSubmitPost = (content, singleRoom) => {
    var data = JSON.stringify({
      message: content,
      chat_room: singleRoom,
    });
    let config = {
      method: "post",
      url: chatMessagesUrl,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log("Message Sent!");
      })
      .catch((error) => {
        console.log("Message Unsent!");
      });
  };
  // set link preview
  const [previewUrl, setPreviewUrl] = useState(null);

  function findUrls(text) {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.match(urlRegex) || [];
  }
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const post_uuid = params.get("post_id");
    if (post_uuid) {
      setPreviewUrl(1);
    }
  }, []);

  // handle navigate details

  const handleNavigateDetails = (data) => {
    navigate("/loungeitemDetail?post_id=" + data.uuid, {
      state: { data: data },
    });
  };

  const [open, setOpen] = React.useState(false);
  const [modalopen, setModalOpen] = React.useState(false);
  // const [showFriends, setShowFriends] = useState(false);
  const [currentView, setCurrentView] = useState("main");

  const handleClickOpen = (uuid) => {
    const baseUrl = window.location.origin; // assuming base URL is current site
    const link = `${baseUrl}/loungeitemDetail?post_id=${uuid}`;
    setshareUrl(link);
    setshareUuid(uuid);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setModalContent(null); // Reset modal content on close
    // setShowFriends(false);
    setCurrentView("main");
    // Show modal after copying
  };

  const modalClose = () => {
    setModalOpen(false);
  };

  // const handleFriendsClick = () => {
  //   setShowFriends(true);
  // };

  const handleFriendsClick = () => {
    setCurrentView("friends");
  };

  const handleFriendListClick = () => {
    setCurrentView("specificFriends");
  };

  const handleGroupListClick = () => {
    setCurrentView("groupList");
  };

  const handleCommuinityListClick = () => {
    setCurrentView("commuinity");
    getlinkedMicrosites();
  };
  const shareToICircles = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("share_icircles", 1);
    const url = `${createFeedsUrl}/${shareUuid}`;

    try {
      const response = await storeFeed({
        token,
        formData,
        url,
        setProgressValue,
      });

      if (response?.data) {
        // Assuming 'ok' is a success indicator
        setProgressValue(0);
        setOpen(false);
        setModalContent(null); // Reset modal content on close
        // setShowFriends(false);
        setCurrentView("main");
        setNotification("Shared successfully!");
      } else {
        setNotification("Failed to share.");
      }
      setTimeout(() => setNotification(null), 3000);
    } catch (error) {
      setNotification("Network error while sharing.");
      console.error("Network error:", error);
      setTimeout(() => setNotification(null), 3000);
    }
  };
  return (
    <Fragment>
      <div className="LoungeItemContent">
        <div className="loungeContent">
          {segName !== "loungeitemDetail?post_id=" + data.uuid &&
            !pinned &&
            data &&
            data.content &&
            data.content != null &&
            data.content !== "" &&
            data !== undefined && (
              <>
                {previewUrl !== null ? (
                  <>
                    <ParagraphWithLinkPreview paragraph={paragraph} />
                  </>
                ) : (
                  <>
                    {paragraph.length > 650 ? (
                      <Box onClick={(e) => handleNavigateDetails(data)}>
                        {paragraph.slice(0, 650)}...
                        <span
                          onClick={(e) =>
                            navigate("/loungeitemDetail?post_id" + data.uuid, {
                              state: { data: data },
                            })
                          }
                        >
                          Read More
                        </span>
                      </Box>
                    ) : (
                      <Box>
                        <ParagraphWithLinkPreview paragraph={paragraph} />
                      </Box>
                    )}
                  </>
                )}
                {/* {paragraph.length> 650 ? <Box onClick={(e)=> handleNavigateDetails(data)}>{paragraph.slice(0,650)}...<span  onClick={(e)=> navigate('/loungeitemDetail', {state:{data:data}})}>Read More</span> </Box>:<Box onClick={(e)=> handleNavigateDetails(data)}>{paragraph}</Box>} */}
                <Box sx={{ mt: 2 }}>
                  {previewUrl !== null && (
                    <a href={previewUrl} target="_blank" rel="noreferrer">
                      <LinkPreviewComponent url={previewUrl} />
                    </a>
                  )}
                </Box>
              </>
            )}
          {/* <ReactReadMoreReadLess
            charLimit={650}
            ellipsis={'...'}
            readLessStyle={{whiteSpace: "nowrap", textDecoration: "none"}}
            readMoreStyle={{whiteSpace: "nowrap", textDecoration: "none"}}
            readMoreText={"Read more"}
            readLessText={"Read less"}
            readMoreClassName="read-more-less--more"
            readLessClassName="read-more-less--less"
            onClick={(e)=> navigate('/loungeitemDetail', {state:{data:data}})}
          >
            {paragraph}
          </ReactReadMoreReadLess> */}

          {segName === "loungeitemDetail?post_id=" + data.uuid && !pinned && (
            <>
              <div className="content">
                {" "}
                <ParagraphWithLinkPreview paragraph={paragraph} />
              </div>
            </>
          )}
        </div>
        {!notification && (
          <div className="loungeItem_footer">
            <div className="loung_item_status_update">
              <div className="status_update_left">
                {loaderVisible === false ? (
                  <div className="statusUpdate_item">
                    <AvatarGroup max={7}>
                      {storeLike && storeLike.length > 0 && (
                        <Tooltip
                          arrow
                          title={
                            <div>
                              {storeLike.map((data, i) => {
                                return (
                                  <Typography
                                    key={data.uuid}
                                    variant="body1"
                                    fontSize={12}
                                  >
                                    {data?.user?.name}
                                  </Typography>
                                );
                              })}
                              {/* Add more lines as needed */}
                            </div>
                          }
                        >
                          <Avatar
                            sx={{ width: 20, height: 20 }}
                            alt="Like"
                            src={likeImage}
                          />
                        </Tooltip>
                      )}
                      {storeLove && storeLove.length > 0 && (
                        <Tooltip
                          arrow
                          title={
                            <div>
                              {storeLove.map((data, i) => {
                                return (
                                  <Typography
                                    key={data.uuid}
                                    variant="body1"
                                    fontSize={12}
                                  >
                                    {data?.user?.name}
                                  </Typography>
                                );
                              })}
                              {/* Add more lines as needed */}
                            </div>
                          }
                        >
                          <Avatar
                            sx={{ width: 20, height: 20 }}
                            alt="Like"
                            src={loveImage}
                          />
                        </Tooltip>
                      )}
                      {storeHappy && storeHappy.length > 0 && (
                        <Tooltip
                          arrow
                          title={
                            <div>
                              {storeHappy.map((data, i) => {
                                return (
                                  <Typography
                                    key={data.uuid}
                                    variant="body1"
                                    fontSize={12}
                                  >
                                    {data?.user?.name}
                                  </Typography>
                                );
                              })}
                              {/* Add more lines as needed */}
                            </div>
                          }
                        >
                          <Avatar
                            sx={{ width: 20, height: 20 }}
                            alt="Like"
                            src={happyImage}
                          />
                        </Tooltip>
                      )}
                      {storeAdore && storeAdore.length > 0 && (
                        <Tooltip
                          arrow
                          title={
                            <div>
                              {storeAdore.map((data, i) => {
                                return (
                                  <Typography
                                    key={data.uuid}
                                    variant="body1"
                                    fontSize={12}
                                  >
                                    {data?.user?.name}
                                  </Typography>
                                );
                              })}
                              {/* Add more lines as needed */}
                            </div>
                          }
                        >
                          <Avatar
                            sx={{ width: 20, height: 20 }}
                            alt="Like"
                            src={adoreImage}
                          />
                        </Tooltip>
                      )}
                      {storeLaugh && storeLaugh.length > 0 && (
                        <Tooltip
                          arrow
                          title={
                            <div>
                              {storeLaugh.map((data, i) => {
                                return (
                                  <Typography
                                    key={data.uuid}
                                    variant="body1"
                                    fontSize={12}
                                  >
                                    {data?.user?.name}
                                  </Typography>
                                );
                              })}
                              {/* Add more lines as needed */}
                            </div>
                          }
                        >
                          <Avatar
                            sx={{ width: 20, height: 20 }}
                            alt="Like"
                            src={laughImage}
                          />
                        </Tooltip>
                      )}
                      {storeAngry && storeAngry.length > 0 && (
                        <Tooltip
                          arrow
                          title={
                            <div>
                              {storeAngry.map((data, i) => {
                                return (
                                  <Typography
                                    key={data.uuid}
                                    variant="body1"
                                    fontSize={12}
                                  >
                                    {data?.user?.name}
                                  </Typography>
                                );
                              })}
                              {/* Add more lines as needed */}
                            </div>
                          }
                        >
                          <Avatar
                            sx={{ width: 20, height: 20 }}
                            alt="Like"
                            src={angryImage}
                          />
                        </Tooltip>
                      )}
                      {storeSad && storeSad.length > 0 && (
                        <Tooltip
                          arrow
                          title={
                            <div>
                              {storeSad.map((data, i) => {
                                return (
                                  <Typography
                                    key={data.uuid}
                                    variant="body1"
                                    fontSize={12}
                                  >
                                    {data?.user?.name}
                                  </Typography>
                                );
                              })}
                              {/* Add more lines as needed */}
                            </div>
                          }
                        >
                          <Avatar
                            sx={{ width: 20, height: 20 }}
                            alt="Like"
                            src={sadImage}
                          />
                        </Tooltip>
                      )}
                    </AvatarGroup>
                    {data.total_reactions > 0 && (
                      <Box sx={{ fontSize: "16px", ml: 1 }}>
                        {data.total_reactions}
                      </Box>
                    )}
                  </div>
                ) : (
                  <div className="statusUpdate_item">
                    <ThreeDots
                      height="30"
                      width="30"
                      radius="9"
                      color="#4fa94d"
                      ariaLabel="three-dots-loading"
                      visible={true}
                    />
                  </div>
                )}

                {data.total_comments > 0 && (
                  <div className="statusUpdate_item">
                    Comments
                    <Box sx={{ fontSize: "16px", ml: 1 }}>
                      {data.total_comments}
                    </Box>
                  </div>
                )}
              </div>
              <div className="status_update_right"></div>
            </div>
            <div className="emojis_container">
              <div className={`${emojiClass} ${data.id}`}>
                {feedsEmoji.map(function (emoji) {
                  return (
                    <span
                      className="singleEmoji"
                      onClick={() =>
                        emojiClickHandler(
                          emoji,
                          data.id,
                          data.my_reactions.length > 0
                            ? data.my_reactions[0].uuid
                            : null
                        )
                      }
                      key={emoji}
                    >
                      {emoji}
                    </span>
                  );
                })}
              </div>
            </div>

            {token ? (
              <div className="loungeItem_footer_status">
                <div className="footer_status_one">
                  {myReaction !== null ? (
                    <span
                      className="emoji_icon"
                      onClick={(e) =>
                        handleRemoveReaction(
                          data.my_reactions.length > 0
                            ? data.my_reactions[0].uuid
                            : null
                        )
                      }
                    >
                      {myReaction}
                    </span>
                  ) : (
                    <div onClick={(e) => handleEmoji(data.id, emojiClass)}>
                      <ThumbUpIcon />
                      <span>Like</span>
                    </div>
                  )}
                </div>
                <div
                  className="footer_status_one"
                  onClick={(e) => itemModalClick()}
                >
                  <ChatBubbleIcon />
                  <span>Comment</span>
                </div>
                <div
                  className="footer_status_one"
                  onClick={(e) => handleCopyLink(data.uuid)}
                >
                  <ContentCopyIcon />
                  <span>Copy</span>
                </div>
                <div
                  className="footer_status_one"
                  onClick={(e) => handleClickOpen(data.uuid)}
                >
                  <ShareIcon />
                  <span>Share</span>
                </div>
              </div>
            ) : null}
          </div>
        )}
      </div>
      <Dialog
        open={modalopen}
        onClose={modalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className="text-center">
          {"Link Copied!"}
        </DialogTitle>
        <DialogContent>
          <div>
            {modalContent === "copyLink" && (
              <>
                <div className="copy-link-content">{copiedLink}</div>
              </>
            )}
          </div>
        </DialogContent>
        <DialogActions className="justify-content-center mb-2">
          <Button onClick={modalClose} variant="contained">
            Done
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="share-dialog"
      >
        {/* Main Body */}
        {currentView === "main" && (
          <>
            <div className="share-close-icon" onClick={handleClose}>
              <CloseIcon />
            </div>
            <DialogTitle id="alert-dialog-title" className="text-center">
              {"Share"}
            </DialogTitle>
            <DialogContent>
              <div className="share-body">
                <div className="profile-container d-flex">
                  <Avatar
                    src={
                      userProfile?.avater
                        ? baseUrl + "/" + userProfile.avater
                        : userInfo?.user?.avater
                        ? baseUrl + "/" + userInfo?.user?.avatar
                        : "https://plus.unsplash.com/premium_photo-1689977968861-9c91dbb16049?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8cHJvZmlsZSUyMHBpY3R1cmV8ZW58MHx8MHx8fDA%3D"
                    }
                    alt={
                      userProfile?.name
                        ? userProfile.name
                        : userInfo?.user?.name
                    }
                    sx={{ width: 45, height: 45 }}
                  />
                  <div className="profile-content">
                    <h5>
                      {userProfile?.name
                        ? userProfile.name
                        : userInfo?.user?.name}
                    </h5>
                    <div className="post-content">
                      <span> My Profile </span>
                      <span onClick={handleFriendsClick}>
                        <GroupIcon /> Friends <ArrowDropDownIcon />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="post-writer-content">
                  <TextareaAutosize
                    aria-label="empty textarea"
                    placeholder="Say something about this (optional)"
                  />
                </div>
                <div className="text-end mt-3">
                  <Button variant="contained" size="medium">
                    Share Now
                  </Button>
                </div> 
                <div className="send-content">
                  <div className="send-content-top">
                  <h5 className="mb-1 w-50 float-left">Send in Private Chat</h5>
                    {/* Search Bar */}
                    <div className="search-container w-40">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search members..."
                        value={memberSearchKeyword}
                        onChange={(e) => setMemberSearchKeyword(e.target.value)}
                        onKeyDown={(e) => e.key === "Enter" && getAllMembers()} // Trigger search on Enter 
                      /> 
                    </div>
                  </div>
                

  <Swiper
    navigation={true}
    slidesPerView={6}
    slidesPerGroup={4}
    spaceBetween={8}
    modules={[Navigation]}
    className="mySwiper"
  >
    {allMembers &&
      allMembers.length > 0 &&
      allMembers.map((data, key) => {
        let parseUser;
        const objLength = Object.keys(data?.user_details)?.length;
        if (objLength > 2) {
          const userOwnerDetails = JSON.parse(data?.user_details);
          if (userOwnerDetails) {
            parseUser = userOwnerDetails;
          }
        }

        const isSelected = selectedProfiles.some(
          (selectedProfile) => selectedProfile.uuid === data.user.uuid
        ); // Check if profile is selected

        return (
          <SwiperSlide
            key={key}
            onClick={() => handleProfileSelection(data.user)}
          >
            <div
              className={`send-content-item ${
                isSelected ? "selected" : ""
              }`} // Add class for selected styles
              style={{
                border: isSelected
                  ? "2px solid #007bff"
                  : "2px solid transparent",
                cursor: "pointer",
              }}
            >
              <Avatar
                src={`${baseUrl}/${parseUser?.avater}`}
                alt={data?.user?.name}
                sx={{ width: 60, height: 60 }}
              />
              <h6>{parseUser?.name || data?.user?.name}</h6>
            </div>
          </SwiperSlide>
        );
      })}
  </Swiper>

  {/* Conditional Rendering of Text Area and Submit Button */}
  {selectedProfiles.length > 0 && (
    <div className="message-box mt-3">
      <textarea
        placeholder="Type your message here"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        rows="4"
        className="form-control mb-2"
      ></textarea>
      <button onClick={handleSubmit} className="btn btn-primary">
        Send Message
      </button>
    </div>
  )}
</div>

                <div className="send-content">
                  <h5 className="mb-2">Share To</h5>
                  <Swiper
                    navigation={true}
                    slidesPerView={6}
                    slidesPerGroup={4}
                    spaceBetween={8}
                    modules={[Navigation]}
                    className="mySwiper"
                  >
                    <SwiperSlide>
                      <div
                        className="share-content-item"
                        onClick={shareToMessenger}
                      >
                        <div className="share-icon">
                          <img src={messenger} alt="" />
                        </div>
                        <h6> Messenger </h6>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div
                        className="share-content-item"
                        onClick={handleCopyLinks}
                      >
                        <div className="share-icon">
                          <LinkIcon />
                        </div>
                        <h6> Copy Link </h6>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div
                        className="share-content-item"
                        onClick={handleGroupListClick}
                      >
                        <div className="share-icon">
                          <GroupIcon />
                        </div>
                        <h6> Group </h6>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div
                        className="share-content-item"
                        onClick={handleCommuinityListClick}
                      >
                        <div className="share-icon">
                          <EmojiFlagsIcon />
                        </div>
                        <h6> Commuinity </h6>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div
                        className="share-content-item"
                        onClick={shareToICircles}
                      >
                        <div className="share-icon">
                          <img src={ic} alt="" />
                        </div>
                        <h6> iCircles </h6>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div
                        className="share-content-item"
                        onClick={shareToWhatsApp}
                      >
                        <div className="share-icon">
                          <WhatsAppIcon />
                        </div>
                        <h6> WhtasApp </h6>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide onClick={shareToTelegram}>
                      <div className="share-content-item">
                        <div className="share-icon">
                          <TelegramIcon />
                        </div>
                        <h6> Telegram </h6>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div
                        className="share-content-item"
                        onClick={shareToFacebook}
                      >
                        <div className="share-icon">
                          <FacebookIcon />
                        </div>
                        <h6> Facebook </h6>
                      </div>
                    </SwiperSlide>

                    <SwiperSlide>
                      <div className="share-content-item" onClick={shareToX}>
                        <div className="share-icon">
                          <XIcon />
                        </div>
                        <h6> X / Twitter </h6>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div
                        className="share-content-item"
                        onClick={shareToLinkedIn}
                      >
                        <div className="share-icon">
                          <LinkedInIcon />
                        </div>
                        <h6> Linkedin </h6>
                      </div>
                    </SwiperSlide>
                  </Swiper>
                </div>
              </div>
            </DialogContent>
          </>
        )}

        {/* Audience Body */}
        {currentView === "friends" && (
          <>
            <div
              className="share-back-icon"
              onClick={() => setCurrentView("main")}
            >
              <ArrowBackIcon />
            </div>
            <DialogTitle id="alert-dialog-title" className="text-center">
              {"Post audience"}
            </DialogTitle>
            <Divider />
            <DialogContent>
              <DialogContentText>
                <h4>Who can see your post?</h4>
                <p className="mt-2">
                  Your post will show up in Feed, on your profile and in search
                  results.
                </p>
                <p className="mt-2">
                  Your default audience is set to Friends, but you can change
                  the audience of this specific post.
                </p>
              </DialogContentText>
              <div className="audiance-content">
                <RadioGroup
                  aria-labelledby="demo-audiance-group"
                  name="audiance-group"
                >
                  <InputLabel className="audiance-lebel">
                    <div className="audiance-text d-flex align-items-center">
                      <i>
                        <PublicIcon />
                      </i>
                      <div>
                        <h5>Everyone </h5>
                        <p>Anyone on or off community</p>
                      </div>
                    </div>
                    <FormControlLabel value="public" control={<Radio />} />
                  </InputLabel>

                  <InputLabel className="audiance-lebel">
                    <div className="audiance-text d-flex align-items-center">
                      <i>
                        <GroupIcon />
                      </i>
                      <div>
                        <h5>Friends </h5>
                        <p>Your friends on community</p>
                      </div>
                    </div>
                    <FormControlLabel value="friends" control={<Radio />} />
                  </InputLabel>
                  <InputLabel className="audiance-lebel">
                    <div className="audiance-text d-flex align-items-center">
                      <i>
                        <PersonPinIcon />
                      </i>
                      <div>
                        <h5>Exclude </h5>
                        <p>Exclude member from the community</p>
                      </div>
                    </div>
                    <FormControlLabel value="except" control={<Radio />} />
                  </InputLabel>

                  <InputLabel
                    className="audiance-lebel"
                    onClick={handleFriendListClick}
                  >
                    <div className="audiance-text d-flex align-items-center">
                      <i>
                        <ManageAccountsIcon />
                      </i>
                      <div>
                        <h5>Include </h5>
                        <p>Specific member on Community</p>
                      </div>
                    </div>
                    <FormControlLabel value="specific" control={<Radio />} />
                  </InputLabel>

                  <InputLabel className="audiance-lebel">
                    <div className="audiance-text d-flex align-items-center">
                      <i>
                        <LockIcon />
                      </i>
                      <div>
                        <h5>None but me </h5>
                        <p>Only your on the Community</p>
                      </div>
                    </div>
                    <FormControlLabel value="onlyMe" control={<Radio />} />
                  </InputLabel>

                  <InputLabel className="audiance-lebel">
                    <div className="audiance-text d-flex align-items-center">
                      <i>
                        <SettingsIcon />
                      </i>
                      <div>
                        <h5>Create list </h5>
                        <p>Include and exclude friends and lists</p>
                      </div>
                    </div>
                    <FormControlLabel value="custom" control={<Radio />} />
                  </InputLabel>
                </RadioGroup>
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setCurrentView("main")}>Cancel</Button>
              <Button
                onClick={() => setCurrentView("main")}
                variant="contained"
                autoFocus
              >
                Done
              </Button>
            </DialogActions>
          </>
        )}

        {/* Friend List Body */}
        {currentView === "specificFriends" && (
          <>
            <div
              className="share-back-icon"
              onClick={() => setCurrentView("friends")}
            >
              <ArrowBackIcon />
            </div>
            <DialogTitle id="alert-dialog-title" className="text-center">
              {"Specific Friends"}
            </DialogTitle>
            <Divider />
            <DialogContent>
              <div className="specificFriends-serachbar">
                {/* <TextField  variant="outlined" fullWidth /> */}
                <input type="text" placeholder="Search for a friend here .." />
                <i>
                  <SearchIcon />
                </i>
              </div>
              <div className="d-flex justify-between align-center mt-3">
                <h6>Friends</h6>
                <h6>
                  {" "}
                  Selected <strong>12</strong>{" "}
                </h6>
              </div>
              <FormGroup>
                <InputLabel className="audiance-lebel">
                  <div className="audiance-text d-flex align-items-center">
                    <Avatar
                      src="https://i.pinimg.com/736x/59/37/5f/59375f2046d3b594d59039e8ffbf485a.jpg"
                      alt=""
                      sx={{ mr: 1 }}
                    />
                    <h6>A K Moyeenuddin Ahmmed </h6>
                  </div>
                  <FormControlLabel control={<Checkbox />} />
                </InputLabel>
                <InputLabel className="audiance-lebel">
                  <div className="audiance-text d-flex align-items-center">
                    <Avatar
                      src="https://media.sproutsocial.com/uploads/2022/06/profile-picture.jpeg"
                      alt=""
                      sx={{ mr: 1 }}
                    />
                    <h6>Masum Billah</h6>
                  </div>
                  <FormControlLabel control={<Checkbox />} />
                </InputLabel>
                <InputLabel className="audiance-lebel">
                  <div className="audiance-text d-flex align-items-center">
                    <Avatar
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSwRPWpO-12m19irKlg8znjldmcZs5PO97B6A&s"
                      alt=""
                      sx={{ mr: 1 }}
                    />
                    <h6>Sohel Ahamed </h6>
                  </div>
                  <FormControlLabel control={<Checkbox />} />
                </InputLabel>
                <InputLabel className="audiance-lebel">
                  <div className="audiance-text d-flex align-items-center">
                    <Avatar
                      src="https://img.freepik.com/premium-photo/stylish-man-flat-vector-profile-picture-ai-generated_606187-310.jpg"
                      alt=""
                      sx={{ mr: 1 }}
                    />
                    <h6>Tashnim Khushbu </h6>
                  </div>
                  <FormControlLabel control={<Checkbox />} />
                </InputLabel>
                <InputLabel className="audiance-lebel">
                  <div className="audiance-text d-flex align-items-center">
                    <Avatar
                      src="https://i.pinimg.com/736x/59/37/5f/59375f2046d3b594d59039e8ffbf485a.jpg"
                      alt=""
                      sx={{ mr: 1 }}
                    />
                    <h6>Fabliha Bushra</h6>
                  </div>
                  <FormControlLabel control={<Checkbox />} />
                </InputLabel>
                <InputLabel className="audiance-lebel">
                  <div className="audiance-text d-flex align-items-center">
                    <Avatar
                      src="https://plus.unsplash.com/premium_photo-1673866484792-c5a36a6c025e?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8cHJvZmlsZXxlbnwwfHwwfHx8MA%3D%3D"
                      alt=""
                      sx={{ mr: 1 }}
                    />
                    <h6>A K Moyeenuddin Ahmmed </h6>
                  </div>
                  <FormControlLabel control={<Checkbox />} />
                </InputLabel>
                <InputLabel className="audiance-lebel">
                  <div className="audiance-text d-flex align-items-center">
                    <Avatar
                      src="https://i.pinimg.com/736x/59/37/5f/59375f2046d3b594d59039e8ffbf485a.jpg"
                      alt=""
                      sx={{ mr: 1 }}
                    />
                    <h6>Herun Akter </h6>
                  </div>
                  <FormControlLabel control={<Checkbox />} />
                </InputLabel>
                <InputLabel className="audiance-lebel">
                  <div className="audiance-text d-flex align-items-center">
                    <Avatar
                      src="https://plus.unsplash.com/premium_photo-1689977968861-9c91dbb16049?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8cHJvZmlsZSUyMHBpY3R1cmV8ZW58MHx8MHx8fDA%3D"
                      alt=""
                      sx={{ mr: 1 }}
                    />
                    <h6>A K Moyeenuddin Ahmmed </h6>
                  </div>
                  <FormControlLabel control={<Checkbox />} />
                </InputLabel>
              </FormGroup>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setCurrentView("friends")}>Cancel</Button>
              <Button
                onClick={() => setCurrentView("friends")}
                variant="contained"
                autoFocus
              >
                Done
              </Button>
            </DialogActions>
          </>
        )}

        {/* Group List Body */}
        {currentView === "groupList" && (
          <>
            <div
              className="share-back-icon"
              onClick={() => setCurrentView("main")}
            >
              <ArrowBackIcon />
            </div>
            <DialogTitle id="alert-dialog-title" className="text-center">
              {"Share to a group"}
            </DialogTitle>
            <Divider />
            <DialogContent>
              <div className="specificFriends-serachbar">
                <input
                  type="text"
                  placeholder="Search for a group here..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                <i>
                  <SearchIcon />
                </i>
              </div>
              <div className="d-flex justify-between align-center mt-3">
                <h6>All Groups</h6>
              </div>
              <FormGroup>
                {filteredGroups !== null && filteredGroups.length === 0 && (
                  <div className="placeholder_text">No Groups Found</div>
                )}
                {filteredGroups !== null &&
                  filteredGroups.length > 0 &&
                  filteredGroups.map((data, key) => (
                    <InputLabel key={key} className="audiance-lebel">
                      <div className="audiance-text d-flex align-items-center">
                        <Avatar
                          src={`${baseUrl}/${data?.thumb}`}
                          alt=""
                          sx={{ mr: 1 }}
                        />
                        <div>
                          <h6>{data?.name}</h6>
                          <p>
                            {" "}
                            {data?.details?.length > 50 ? (
                              <>{parser(data.details.slice(0, 50))}</>
                            ) : (
                              <>{parser(data?.details)}</>
                            )}{" "}
                          </p>
                        </div>
                      </div>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectedGroupIds.includes(data.id)}
                            onChange={() => handleCheckboxChange(data.id)}
                          />
                        }
                      />
                    </InputLabel>
                  ))}
              </FormGroup>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setCurrentView("main")}>Cancel</Button>
              <Button onClick={handleDone} variant="contained" autoFocus>
                Done
              </Button>
            </DialogActions>
          </>
        )}

        {/* Group List Body */}
        {currentView === "commuinity" && (
          <>
            <div>
              <div
                className="share-back-icon"
                onClick={() => setCurrentView("main")}
              >
                <ArrowBackIcon />
              </div>
              <DialogTitle id="alert-dialog-title" className="text-center">
                {"Share to Linked Community"}
              </DialogTitle>
              <Divider />
              <DialogContent>
                <div className="specificFriends-serachbar">
                  <input
                    type="text"
                    name="keyword"
                    placeholder="Search for a community here .."
                    value={keyword}
                    onChange={(e) => setKeyword(e.target.value)} // Update keyword
                  />
                  <i>
                    <SearchIcon />
                  </i>
                </div>
                <div className="d-flex justify-between align-center mt-3">
                  <h6>All Community</h6>
                </div>
                <FormGroup>
                  {linkedMicrosites === null ||
                    (linkedMicrosites.length == 0 && (
                      <div className="placeholder_text">
                        {linkedMicrositeMessage}
                      </div>
                    ))}
                  {linkedMicrosites !== null &&
                    linkedMicrosites.length > 0 &&
                    linkedMicrosites.map((data, key) => (
                      <InputLabel className="audiance-lebel" key={key}>
                        <div className="audiance-text d-flex align-items-center">
                          <Avatar
                            src={`${baseUrl}/${data?.entity_logo}`}
                            alt={data?.name}
                            sx={{ mr: 1 }}
                          />
                          <div>
                            <h6>{data?.name}</h6>
                            <p>
                              {data?.tagline ? cleanText(data.tagline) : ""}
                            </p>
                          </div>
                        </div>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={selectedIds.includes(data.id)}
                              onChange={() =>
                                handleCheckboxChangeCommunity(data.id)
                              }
                            />
                          }
                        />
                      </InputLabel>
                    ))}
                </FormGroup>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setCurrentView("main")}>Cancel</Button>
                <Button onClick={shareCommunity} variant="contained" autoFocus>
                  Done
                </Button>
              </DialogActions>
            </div>
          </>
        )}
      </Dialog>
      {notifications && (
        <div className="notification bottom-left">{notifications}</div>
      )}{" "}
      {/* Notification message */}
    </Fragment>
  );
};

export default LoungeItemContent;
