import React from "react";
import {
  Typography,
  Container,
  TextField,
  Button,
  Grid,
  FormControlLabel,
  Checkbox,
  Link,
  AppBar,
  Toolbar,
  Box,
  List,
  ListItem,
  Divider,
} from "@mui/material";

const PrivacyPolicyPage = () => {
  return (
    <div style={{ padding: "20px", lineHeight: "1.6", fontFamily: "Arial, sans-serif" }}>
    <h1>Privacy Policy</h1>
    <p>
      Welcome to Community Microsite ("Community Microsite," "we," "us," or
      "our"). This privacy policy applies to the Community Microsite app
      (hereby referred to as “Application”) for mobile devices that was created
      by iCircles LLC (hereby referred to as “Service Provider”) as a Free
      service. This service is intended for use "AS IS".
    </p>

    <h2>Information Collection and Use</h2>
    <p>
      The Application collects information when you download and use it. This
      information may include information such as:
    </p>
    <ul>
      <li>Device type and operating system</li>
      <li>IP address</li>
      <li>Unique device identifiers</li>
    </ul>
    <p>
      The Application collects your device's location, which helps the Service
      Provider determine your approximate geographical location and make use
      of in below ways:
    </p>
    <ul>
      <li>
        <strong>Geolocation Services:</strong> The Service Provider utilizes
        location data to provide features such as personalized content,
        relevant recommendations, and location-based services.
      </li>
      <li>
        <strong>Analytics and Improvements:</strong> Aggregated and anonymized
        location data helps the Service Provider to analyze user behavior,
        identify trends, and improve the overall performance and functionality
        of the Application.
      </li>
    </ul>

    <h3>Account Information</h3>
    <ul>
      <li>Username</li>
      <li>Email address</li>
      <li>Any additional information you voluntarily provide</li>
    </ul>

    <h3>Profile Information</h3>
    <p>You can decide to provide additional information to your profile, such as:</p>
    <ul>
      <li>Your name</li>
      <li>Biography</li>
      <li>Interests</li>
      <li>Profile photo</li>
    </ul>

    <h3>Activity Information</h3>
    <p>
      We collect details about your interactions within the App, including:
    </p>
    <ul>
      <li>Content you view and interact with</li>
      <li>Groups you join</li>
      <li>Users you follow</li>
    </ul>

    <h2>How We Use Your Information</h2>
    <p>We use your information to:</p>
    <ul>
      <li>Provide and operate the App</li>
      <li>Create and maintain your account</li>
      <li>Send you notifications</li>
      <li>Send you marketing communications</li>
      <li>Analyze how you use the App</li>
      <li>Personalize your experience</li>
      <li>Find and connect you with people</li>
      <li>Comply with legal requirements</li>
    </ul>

    <h2>Third-Party Access</h2>
    <p>
      Only aggregated, anonymized data is periodically transmitted to external
      services to aid the Service Provider in improving the Application and
      their service. The Service Provider may share your information with
      third parties in the ways described in this privacy statement.
    </p>
    <p>
      The Application utilizes third-party services that have their own
      Privacy Policy about handling data. Below are the links to the Privacy
      Policy of the third-party service providers used by the Application:
    </p>
    <ul>
      <li>
        <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">
          Google Play Services
        </a>
      </li>
      <li>
        <a href="https://onesignal.com/privacy_policy" target="_blank" rel="noopener noreferrer">
          One Signal
        </a>
      </li>
    </ul>

    <h2>Child Safety Standards Policy - Part 1</h2>
    <p><strong>Effective Date:</strong> March 8, 2024</p>
    <p>
      At Community Microsite app, safeguarding children is a critical priority.
      This policy outlines our commitment to preventing child sexual abuse and
      exploitation (CSAE) and ensuring a safe platform for all users.
    </p>
    <h3>1. Standards Against Child Sexual Abuse and Exploitation (CSAE)</h3>
    <p>Community Microsite strictly prohibits:</p>
    <ul>
      <li>Child Sexual Abuse Material (CSAM): Any content depicting or facilitating child sexual exploitation.</li>
      <li>Grooming and Exploitation: Behaviors or activities aimed at manipulating or exploiting children.</li>
      <li>Harmful Content: Content promoting violence, abuse, or endangerment of children.</li>
    </ul>
    <p>
      We take a zero-tolerance approach to CSAE and actively remove and report such content to relevant authorities.
    </p>

    <h3>2. In-App Mechanism for User Feedback</h3>
    <p>
      Users can report inappropriate or harmful content directly through the app using the following mechanisms:
    </p>
    <ul>
      <li>Report Button: Available on user profiles, posts, and messages.</li>
      <li>Feedback Form: Accessible in the app settings for general concerns or suggestions.</li>
      <li>Email Contact: Users can email info@icircle.app for urgent child safety concerns.</li>
    </ul>

    <h3>3. Addressing Child Sexual Abuse Material (CSAM)</h3>
    <p>To combat CSAM effectively, Community Microsite employs the following measures:</p>
    <ul>
      <li>Content Monitoring: Automated systems and trained moderators review user-generated content to detect and remove harmful material.</li>
      <li>Reporting to Authorities: All identified CSAM is promptly reported to law enforcement and child protection agencies.</li>
      <li>Collaboration: We work with organizations and coalitions dedicated to eradicating online CSAE, including compliance with Tech Coalition best practices.</li>
    </ul>

    <h2>Child Safety Standards Policy - Part 2</h2>

    <h3>4. Compliance with Child Safety Laws</h3>
    <p>Community Microsite complies with applicable child safety laws, including:</p>
    <ul>
      <li>COPPA (Children's Online Privacy Protection Act): Ensuring data privacy and security for users under 13 years old.</li>
      <li>GDPR (General Data Protection Regulation): Protecting the personal data of all users, including minors, in the European Union.</li>
      <li>Local Regulations: Adhering to child protection laws in all jurisdictions where the app is available.</li>
    </ul>
    <p>We regularly update our practices to align with evolving child safety legislation.</p>

    <h3>5. Child Safety Point of Contact</h3>
    <p>For child safety-related concerns, users can contact our dedicated Child Safety Team at:</p>
    <ul>
      <li>Email: info@icircle.app</li>
      <li>Phone: +880 1907-108700 (For urgent matters only)</li>
      <li>In-App Support: Available in the app under ‘Report to admin and Report to developer’</li>
    </ul>
    <p>
      Our team ensures timely responses to all inquiries and takes appropriate action to address reported issues.
    </p>

    <h3>6. Data Retention and Privacy</h3>
    <ul>
      <li>Minimal Data Collection: We collect only the data necessary to provide services and protect user safety.</li>
      <li>Data Removal Requests: Parents or guardians may request the deletion of their child’s data by contacting info@icircle.app.</li>
    </ul>

    <h3>7. Policy Updates</h3>
    <p>
      This Child Safety Standards Policy may be updated periodically to enhance our practices and comply with new legal requirements. Users will be notified of significant changes through in-app notifications.
    </p>

    <h3>8. Enforcement and Accountability</h3>
    <p>Community Microsite enforces this policy through:</p>
    <ul>
      <li>Account suspension or termination for violations.</li>
      <li>Collaboration with law enforcement to prosecute offenders.</li>
      <li>Regular audits to assess and improve our child safety measures.</li>
    </ul>
    <p>
      <strong>Your Commitment:</strong> By using Community Microsite, you agree to abide by these standards and report any violations. Together, we can create a safer digital environment for children.
    </p>

    <h2>Children</h2>
    <p>
      The Service Provider does not use the Application to knowingly solicit
      data from or market to children under the age of 13. If you are a
      parent or guardian and you are aware that your child has provided us
      with personal information, please contact the Service Provider
      (info@icircles.app) so that they will be able to take the necessary
      actions.
    </p>

    <h2>Security</h2>
    <p>
      The Service Provider is concerned about safeguarding the confidentiality
      of your information. The Service Provider provides physical,
      electronic, and procedural safeguards to protect information the Service
      Provider processes and maintains.
    </p>
  </div>
  );
};

export default PrivacyPolicyPage;
